// src/components/LoadingOverlay.js
import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50">
      <div className="loader mb-4"></div> {/* Spinner */}
      <div className="text-white text-lg">Loading...</div>
    </div>
  );
};

export default LoadingOverlay;