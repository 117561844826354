import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Correct import paths for Heroicons v2

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      navigate('/');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <header className="w-full py-4 bg-black fixed top-0 left-0 right-0 flex justify-between items-center shadow-md z-50 px-6">
      {/* Logo - Adjust size for mobile */}
      <img
        src="https://cdn.prod.website-files.com/65c5332d5eb951bbf0fe2e26/66513aa69f85c121a57439da_DollyVarden-p-500.png"
        alt="Company Logo"
        className="h-6 md:h-8" // Adjusts the logo size on mobile
      />
      {/* Hamburger Menu for mobile */}
      <div className="md:hidden flex items-center">
        <button
          onClick={toggleMobileMenu}
          className="text-white focus:outline-none"
        >
          {mobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
      </div>
      {/* Desktop Account Button */}
      <div className="hidden md:flex items-center">
        <button
          onClick={toggleDropdown}
          className="bg-gray-800 text-white px-4 py-2 rounded-lg focus:outline-none"
        >
          Account
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        )}
      </div>
      {/* Mobile Menu Dropdown */}
      {mobileMenuOpen && (
        <div className="absolute top-14 right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20 md:hidden">
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Logout
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
