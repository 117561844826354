import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full text-gray-400 text-center py-4 mt-10">
      <div className="flex justify-center space-x-4">
        <a 
          href="https://dollyvardensilver.com/disclosure/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="hover:text-white transition duration-300"
        >
          Terms & Conditions
        </a>
        <span>|</span>
        <a 
          href="https://dollyvardensilver.com/privacy-policy/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="hover:text-white transition duration-300"
        >
          Privacy Policy
        </a>
      </div>
      <p>
        © 2024 Orbiton, Inc. All rights reserved 
      </p>
    </footer>
  );
};

export default Footer;
