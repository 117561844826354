import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';  // Ensure you have the Header component
import Footer from './Footer';  // Ensure you have the Footer component

const OrderConfirmation = () => {
  const location = useLocation();
  const { stockPrice, symbolInfo, orderDetails } = location.state || {};

  if (!orderDetails) {
    return <div className="text-red-500 text-center text-lg mt-20">Error: Missing order details</div>;
  }

  return (
    <div className="min-h-screen flex flex-col justify-between bg-black text-white p-6">
      <Header />

      {/* Main Content */}
      <div className="flex flex-1 items-center justify-center p-4 mt-16"> {/* Added margin-top for space */}
        <div className="flex flex-col items-center justify-center bg-black text-white max-w-4xl w-full p-8 space-y-8">
          {/* Confirmation Message */}
          <div className="w-full text-center flex flex-col justify-center space-y-4">
            <h1 className="text-4xl font-bold mb-6">🎉 Wow! You Just Doubled Your Entries! 🎉</h1>
            <p className="text-lg mb-6">
              You now have <span className="font-semibold">202 entries</span> for a chance to win a 10 oz. silver bar!
            </p>

            {/* YouTube Video Embed */}
            <div className="w-full lg:w-3/4 mx-auto mb-8"> {/* Center the video on desktop */}
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/hh-6RlpFE5Q?si=JxyZXdD8-PQV_pQr"  // Replace with the correct YouTube video ID
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full rounded-md shadow-lg"
              ></iframe>
            </div>

            {/* Order Details with White Border */}
            <div className="w-full max-w-2xl mx-auto border border-white rounded-lg p-6 shadow-md">
              <h2 className="text-3xl font-bold mb-4 text-center mt-6">Order Confirmation</h2> {/* Added margin-top for space */}
              <table className="w-full text-left text-lg">
                <tbody>
                  <tr className="border-b border-gray-700">
                    <td className="py-3 font-semibold">Stock:</td>
                    <td className="py-3 text-right">{symbolInfo.name} ({symbolInfo.ticker}) - {symbolInfo.currency}</td> {/* Right-aligned value */}
                  </tr>
                  <tr className="border-b border-gray-700">
                    <td className="py-3 font-semibold">Price:</td>
                    <td className="py-3 text-right">{stockPrice} CAD</td> {/* Right-aligned value */}
                  </tr>
                  <tr className="border-b border-gray-700">
                    <td className="py-3 font-semibold">Order Type:</td>
                    <td className="py-3 text-right">{orderDetails.orderType}</td> {/* Right-aligned value */}
                  </tr>
                  {orderDetails.orderType === 'Limit' && (
                    <tr className="border-b border-gray-700">
                      <td className="py-3 font-semibold">Limit Price:</td>
                      <td className="py-3 text-right">{orderDetails.limitPrice}</td> {/* Right-aligned value */}
                    </tr>
                  )}
                  <tr className="border-b border-gray-700">
                    <td className="py-3 font-semibold">Quantity:</td>
                    <td className="py-3 text-right">{orderDetails.quantity}</td> {/* Right-aligned value */}
                  </tr>
                  <tr>
                    <td className="py-3 font-semibold">Total Cost:</td>
                    <td className="py-3 text-right">{(orderDetails.quantity * stockPrice).toFixed(2)} CAD</td> {/* Right-aligned value */}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="text-center mt-6">
              <button
                onClick={() => window.open('https://t.me/+zuwG6brAPDs5Mzlh', '_blank')}  // Opens Telegram invite in a new tab
                className="px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Join Telegram
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OrderConfirmation;
