import React, { useEffect, useState, useRef } from 'react';
import OrderModal from '../components/OrderModal';
import LoadingScreen from './LoadingScreen';
import Header from './Header';
import Footer from './Footer';
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component
import { SymbolOverview } from 'react-tradingview-embed';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PostAuthorization = () => {
  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stockPrice, setStockPrice] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({ orderType: 'Market', quantity: 1, limitPrice: '' });
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userSecret, setUserSecret] = useState(null);
  const [disableInteractions, setDisableInteractions] = useState(false);
  
  const tradingViewRef = useRef(null);

  useEffect(() => {
    if (tradingViewRef.current) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
      script.async = true;
      
      script.innerHTML = JSON.stringify({
        "symbol": "TSXV:DV",
        "width": "100%",
        "locale": "en",
        "colorTheme": "dark",
        "isTransparent": true,
      });
      tradingViewRef.current.appendChild(script);
    }

    return () => {
      if (tradingViewRef.current) {
        tradingViewRef.current.innerHTML = ''; // Clean up the script when component unmounts
      }
    };
  }, []);

  useEffect(() => {
    const supabaseUserId = localStorage.getItem('supabaseUserId');
    console.log('Supabase User ID retrieved from local storage:', supabaseUserId);

    if (!supabaseUserId) {
      setError('Supabase User ID is missing.');
      setLoading(false);
      return;
    }

    const fetchUserCredentials = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${backendUrl}/post-authorization`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Supabase-User-ID': supabaseUserId,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch user credentials: ${errorText}`);
        }

        const data = await response.json();
        setUserId(data.userId);
        setUserSecret(data.userSecret);
        console.log('Fetched user credentials:', data);
      } catch (err) {
        console.error('Error fetching user credentials:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCredentials();
  }, []);

  useEffect(() => {
    if (!userId || !userSecret) return;

    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${backendUrl}/accounts?userId=${userId}&userSecret=${userSecret}`);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch accounts: ${errorText}`);
        }

        const data = await response.json();
        console.log('Accounts fetched from backend:', data); // Check the console to see all account data
        setAccounts(data.filter(account => !account.meta?.type.includes('crypto')));
      } catch (err) {
        console.error('Error fetching accounts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [userId, userSecret]);

  const handleSelectAccount = async (accountId) => {
    setDisableInteractions(true); // Disable interactions when an account is clicked
    setIsLoading(true); // Show loading overlay
    setSelectedAccount(accountId);
    try {
      const symbolResponse = await fetch(`${backendUrl}/find-symbol?userId=${userId}&userSecret=${userSecret}&ticker=DV.V&exchange=TSXV&accountId=${accountId}`);
      if (!symbolResponse.ok) throw new Error('Failed to fetch symbol');
      const symbolData = await symbolResponse.json();
      const universalSymbolId = symbolData.id;

      setCompanyName(symbolData.description);

      const stockResponse = await fetch(`${backendUrl}/stock-price?userId=${userId}&userSecret=${userSecret}&accountId=${accountId}&universalSymbolId=${universalSymbolId}`);
      if (!stockResponse.ok) throw new Error('Failed to fetch stock price');
      const stockData = await stockResponse.json();
      setStockPrice(stockData[0]?.last_trade_price);
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error fetching symbol or stock price:', err);
      setError(err.message);
    } finally {
      setIsLoading(false); // Hide loading overlay
      setDisableInteractions(false);
    }
  };

  const handleBuyStock = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          userSecret,
          accountId: selectedAccount,
          ticker: 'DV.VN',
          quantity: orderDetails.quantity,
          orderType: orderDetails.orderType,
          limitPrice: orderDetails.orderType === 'Limit' ? orderDetails.limitPrice : undefined,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to place order: ${errorText}`);
      }

      const data = await response.json();
      setIsModalOpen(false);
      return true;
    } catch (err) {
      console.error('Error placing order:', err);
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
      setDisableInteractions(false); // Re-enable interactions after action is complete
    }
  };

  if (loading || accounts === null) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div 
      className={`min-h-screen bg-black text-white flex flex-col items-center justify-center p-9 sm:px-6 lg:px-9 ${disableInteractions ? 'pointer-events-none' : ''}`}
    >
      <Header />

      {/* Display the loading overlay when isLoading is true */}
      {isLoading && <LoadingOverlay />}

      {/* Adding space above the TradingView widget */}
      <div className="mt-12 mb-8 w-full max-w-6xl" ref={tradingViewRef} style={{ height: '460px' }}>
      
        <h1 className="text-4xl font-bold mb-4 text-center">🎉 You Have 101 Entries! 🎉</h1>
        <p className="text-xl mb-6 text-center">
            You just got another <span className="font-semibold">101 entries</span> for a chance to win a 10 oz. Silver Bar!
        </p>
        <SymbolOverview widgetProps={{
          "theme": "dark",
          "symbols": [["TSXV:DV|1M"], ["OTC:DOLLF|1M"]],
          "showVolume": true,
          "backgroundColor": "rgba(19, 23, 34, 0)",
          "widgetFontColor": "rgba(255, 255, 255, 1)"
        }} />
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center bg-black text-white rounded-lg shadow-lg max-w-6xl w-full mt-20 p-8 space-y-8 lg:space-y-0 lg:space-x-12 px-2 sm:px-4 lg:px-6">
        <div className="w-full lg:w-1/2 text-center lg:text-left flex flex-col justify-center space-y-4">
          <p className="text-lg mb-6 text-center">
            You're now entered into the giveaway with <span className="font-semibold">101 entries</span>! 
          </p> 
          <p className="text-lg mb-6 text-center"> 
              Dolly Varden Silver has one of the largest silver deposits and is held by billionaire investor, Eric Sprott. There's no minimum to invest and you can invest now by selecting your account below!
          </p>
          <h1 className="text-3xl font-bold mb-6 mt-8 text-center">💰 Double Your Entries 💰</h1>
          <h2 className='text-lg font-bold text-center'> Get 202 Entries Into The Giveaway When You Do Your First Trade </h2>
          <h1 className='text-xl font-bold uppercase text-center'> Select Your Account To Double Your Entries </h1>
          {accounts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {accounts.map((account) => {
              // Log to verify the balance data
              console.log(`Rendering account: ${account.name} - Balance: ${JSON.stringify(account.balance)}`);

              // Safely access balance amount and currency
              const balanceAmount = account.balance?.total?.amount ?? 'N/A'; 
              const balanceCurrency = account.balance?.total?.currency ?? 'N/A'; 

              return (
                <div
                  key={account.id}
                  className="p-4 bg-gray-800 rounded-lg shadow-md hover:bg-gray-700 cursor-pointer transition duration-300"
                  onClick={() => handleSelectAccount(account.id)}
                >
                  <h2 className="text-xl font-bold mb-2">{account.name.replace('Wealthsimple Trade', '')}</h2>
                  <p className="text-gray-400">{balanceCurrency}</p>
                  <p className="text-gray-400">
                    Buying Power: ${typeof balanceAmount === 'number' ? balanceAmount.toFixed(2) : '0.00'} {balanceCurrency}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center mt-6">Trading not supported</div>
        )}

        </div>
        {/* YouTube Video Embed */}
        <div className="w-full lg:w-1/2">
          <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
            <iframe
              src="https://www.youtube.com/embed/J6ntspufnyU?si=KIpFvCaSPPdf4X3M" // Replace YOUR_VIDEO_ID with the actual video ID
              title="Silver Shortage Explained"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full rounded-md shadow-lg"
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />

      <OrderModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        stockPrice={stockPrice}
        symbolInfo={{ name: companyName, ticker: 'DV.V', currency: 'CAD' }}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        handleBuyStock={handleBuyStock}
      />
    </div>
  );
};

export default PostAuthorization;
