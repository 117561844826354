// /src/components/OrderModal.js

import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const OrderModal = ({ isOpen, onRequestClose, stockPrice, symbolInfo, orderDetails, setOrderDetails, handleBuyStock }) => {
  const navigate = useNavigate();

  const handlePlaceOrder = async () => {
    const success = await handleBuyStock();
    if (success) {
      navigate('/confirmation', { state: { stockPrice, symbolInfo, orderDetails } });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Place Order"
      className="bg-gray-900 rounded-lg shadow-lg p-6 w-full max-w-lg mx-auto mt-20 text-white"
      overlayClassName="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center" // Adjusted opacity
    >
      <h2 className="text-3xl font-semibold mb-4">Place Order</h2>
      <p className="text-xl mb-4">{symbolInfo.name} ({symbolInfo.ticker}) - {symbolInfo.currency}</p>
      <p className="text-xl mb-2">Current Price: <span className="font-semibold">{stockPrice} CAD</span></p>
      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Order Type:</label>
        <select
          value={orderDetails.orderType}
          onChange={(e) => setOrderDetails({ ...orderDetails, orderType: e.target.value })}
          className="w-full px-3 py-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="Market">Market</option>
          <option value="Limit">Limit</option>
        </select>
      </div>
      {orderDetails.orderType === 'Limit' && (
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">Limit Price:</label>
          <input
            type="number"
            value={orderDetails.limitPrice}
            onChange={(e) => setOrderDetails({ ...orderDetails, limitPrice: e.target.value })}
            className="w-full px-3 py-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}
      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Quantity:</label>
        <input
          type="number"
          value={orderDetails.quantity}
          onChange={(e) => setOrderDetails({ ...orderDetails, quantity: e.target.value })}
          className="w-full px-3 py-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          min="1"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <button onClick={onRequestClose} className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition duration-300">Cancel</button>
        <button onClick={handlePlaceOrder} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">Place Order</button>
      </div>
    </Modal>
  );
};

export default OrderModal;
