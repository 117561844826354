// src/components/SignUp.js
import React, { useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../supabaseClient'; // Ensure the correct path to your Supabase client setup
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const SignUp = () => {
  const navigate = useNavigate();

  // Handle direct Supabase Auth state change
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Direct Supabase Event:', event, session); // Debugging line
      if (event === 'SIGNED_IN') {
        navigate('/enter-giveaway'); // Correct route for the next step
      }
    });
  }, [navigate]); // Make sure to include navigate in the dependency array

  return (
    <div className="flex flex-col min-h-screen text-white bg-black">
      {/* Header Section */}
      <header className="flex items-center justify-center w-full py-4 bg-black">
        <img
          src="https://cdn.prod.website-files.com/65c5332d5eb951bbf0fe2e26/66513aa69f85c121a57439da_DollyVarden-p-500.png" // Replace with your actual logo URL
          alt="Company Logo"
          className="h-12"
        />
      </header>

      {/* Centered Text Section */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-8 text-center">
        <h2 className="mb-2 text-3xl font-bold">We're Giving Away Silver!</h2>
        <p className="mb-6 text-lg">
          Enter for a chance to <span className="font-bold">win a 10 ounce certified Silver bar.</span>
        </p>
      </div>

      {/* Main Content */}
      <div className="flex items-center justify-center flex-1 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col w-full max-w-6xl overflow-hidden bg-black rounded-lg shadow-lg md:flex-row mobile-reverse">
          
          {/* Video Section */}
          <div className="flex items-center justify-center order-2 p-4 md:w-2/3 md:p-8 md:order-1">
            <div className="w-full">
              <iframe 
                width="100%" 
                height="450" 
                src="https://www.youtube-nocookie.com/embed/ZvQsi2V98LM?si=9PHZX9eBtbpeYF63&mute=0&autoplay=1" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
                className="rounded-md shadow-md"
              ></iframe>
            </div>
          </div>

          {/* Sign Up Form Section */}
          <div className="flex flex-col justify-center order-1 p-8 md:w-1/3 md:order-2">
            <h2 className="mb-4 text-2xl font-bold text-center">
              We're only accepting 500 entries so act now before it's gone!
            </h2>
            <Auth
              supabaseClient={supabase}
              view="sign_up"
              appearance={{
                theme: ThemeSupa,
                style: {
                  button: { background: 'white', color: 'black', borderColor: 'white' },
                  anchor: { color: 'white' },
                  input: { background: '#1a1a1a', color: 'white' },
                },
                variables: {
                  default: {
                    button_label: 'Enter Giveaway',
                  },
                },
              }}
              providers={[]}
              theme="dark"
              socialLayout="vertical"
            />
            {/* Live Count Section */}
            <div className="flex items-center justify-center mt-4">
              <span className="mr-2 live-dot"></span>
              <span className="font-bold text-red-600 uppercase text-md">Live Count: </span>
              <span className='font-bold text-white text-md'> 200 Members</span>
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <section className="px-4 py-8 text-white bg-black sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="mb-4 text-3xl font-bold">After Helping Build A Billion Dollar Company, And Take Dolly Varden Silver From A $20M Market Cap To $300M...</h2>
          <img
            src="https://cdn.prod.website-files.com/65c5332d5eb951bbf0fe2e26/667df8666d9f5eda8bcf9167_Screenshot%202024-06-27%20at%204.36.50%E2%80%AFPM-p-800.png"
            alt="Dolly Varden Silver Corp Chart"
            className="mx-auto my-4 rounded-xl"
          />
          <p className="text-lg">
            I'm giving back to the investor community that helped make that happen. Dolly Varden Silver has been an amazing journey and allowed me to do business with billionaires like Frank Giustra and Eric Sprott. Both hold significant position in my company, Dolly Varden Silver.
          </p>
          <img
            src="https://cdn.prod.website-files.com/65c5332d5eb951bbf0fe2e26/667dfbeff8f49cb5843143b3_frank_eric_quality-p-2000.png"
            alt="Billionaires"
            className="mx-auto my-4 rounded-xl"
          />
        </div>
      </section>

      <Footer />
    </div>    
  );
};

export default SignUp;
